import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
export default {
  computed: {
    preset: function preset() {
      return this.$store.getters['player/popupPresetPackage'];
    },
    offer: function offer() {
      var _this$preset;

      return (_this$preset = this.preset) === null || _this$preset === void 0 ? void 0 : _this$preset.promoOfferPreset;
    },
    isShowAvailableTill: function isShowAvailableTill() {
      return this.$store.getters['popupPresetPackage/availableTill'];
    },
    depositsCount: function depositsCount() {
      return this.$store.getters['player/profile'].depositsCount;
    },
    availableTill: function availableTill() {
      var timeLeft = this.$store.getters['popupPresetPackage/availableTill'];
      console.log('popup', timeLeft);
      var seconds = Math.floor(timeLeft % 60).toString();
      var minutes = Math.floor(timeLeft / 60 % 60).toString();
      var hours = Math.floor(timeLeft / 3600).toString();
      return "".concat(hours.length === 1 ? "0".concat(hours) : hours, ":").concat(minutes.length === 1 ? "0".concat(minutes) : minutes, ":").concat(seconds.length === 1 ? "0".concat(seconds) : seconds);
    }
  },
  methods: {
    openCash: function openCash() {
      // this.$store.dispatch('promotions/setOffer', this.preset.promoOfferPreset);
      this.$store.dispatch('promotions/setOfferId', this.preset.promoOfferPreset.id);
      this.$store.$cash.getPromoType();
      this.$store.$cash.getPromoId(); // this.$store.$cash.getPromo();

      this.$store.$cash.open({
        url: "/cash/deposit-by-money/".concat(this.preset.promoOfferPreset.id, "/promoOffer/")
      });
    }
  }
};